<script lang="ts">
	import teaserPoster from "../assets/videos/brano-hp-teaser-en-under-bonnet.jpg?webp&w=1920&imagetools";
	import teaser from "../assets/videos/brano-hp-teaser-en-under-bonnet.mp4";
	import teaserMobile from "../assets/videos/brano-hp-teaser-en-under-bonnet-mobile.mp4";
	import type { ActionReturn } from "svelte/action";
	import StaticVideo from "./Video/StaticVideo.svelte";
	import { hideHeaderStore } from "../stores/hideHeaderStore.js";
	import { getTranslate } from "../utils/getTranslate.js";
	import translations from "./LookInside.translations.json";
	import { tick } from "svelte";
	import { scrollSmootherStore } from "../stores/scrollSmootherStore.js";

	const translate = getTranslate(translations);
	let videoElement = null as HTMLVideoElement | null;

	let visible = false;
	let snapTimeout: number | undefined;
	function observeVisibilityAndSnap(element: HTMLElement): ActionReturn {
		function snap(): void {
			clearTimeout(snapTimeout);
			snapTimeout = window.setTimeout(() => {
				if (!visible || element.clientHeight <= 0.75 * window.innerHeight) {
					return;
				}

				if ($scrollSmootherStore) {
					$scrollSmootherStore.scrollTo(element, true, "center center");
				} else {
					element.scrollIntoView({
						behavior: "smooth",
						block: "center",
						inline: "center",
					});
				}
			}, 750);
		}

		const observer = new IntersectionObserver(
			([entry]) => {
				if (!entry) {
					return;
				}

				visible = entry.isIntersecting;
				hideHeaderStore.set(visible);

				if (!visible) {
					void tick().then(() => {
						if (document.fullscreenElement !== videoElement) {
							videoElement?.pause();
						}
					});
					clearTimeout(snapTimeout);
					window.removeEventListener("scroll", snap);
				} else {
					snap();
					window.addEventListener("scroll", snap);
				}
			},
			{ threshold: [0.6] },
		);

		observer.observe(element);

		return {
			destroy(): void {
				observer.unobserve(element);
			},
		};
	}
</script>

<div class="flex flex-col gap-16 pt-8 lg:py-32">
	<h2 class="container text-[2.75rem] font-semibold lg:text-4xl">{translate("takeALook")}</h2>
	<div use:observeVisibilityAndSnap class="flex flex-col items-center justify-center lg:h-screen">
		<div class="relative m-auto">
			<StaticVideo
				bind:element={videoElement}
				video={teaser}
				poster={teaserPoster}
				controls
				disabled={!visible}
				notMuted
				dontPlayInline
				icon
				videoClass="mx-auto !w-full max-h-screen object-contain lg:object-cover transition-all duration-300 ease-out {visible
					? 'scale-100'
					: 'scale-90'}"
				sourcesets={{
					"<md": teaserMobile,
				}}
			/>
		</div>
	</div>
</div>
